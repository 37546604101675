
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { ResponseDataStringArray as rdsa } from "@/interfaces";
import { UserFormData } from "@/services/auth/auth-service";
import { sleep } from "@/utils";
import * as types from "@/store/types";

@Component({
    methods: {
        ...mapActions({
            authLogin: types.AUTH_LOGIN
        })
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER,
            services: types.SERVICIOS,
            idioma: types.IDIOMA
        })
    }
})
export default class SigninForm extends Vue {
    authLogin!: (userData: UserFormData) => void;
    services?: string[];
    userIdentifier?: number;
    idioma?: string;
    alerta = false;
    Talerta: string[] = ["-1", ""];

    formData: UserFormData = {
        email: "",
        password: ""
    };

    loading = false;

    errorData = {
        name: null,
        message: null
    };

    async created() {
        try {
            const serverResponse = await this.$api.checkServer<rdsa>();
            if (serverResponse.ok) {
                if (serverResponse.data.length > 0) {
                    this.alerta = true;
                    this.Talerta = ["0", ""];
                }
            }
        } catch (error) {
            this.alerta = true;
            this.Talerta = ["1", ""];
        }
    }

    async login() {
        this.loading = true;

        try {
            await this.authLogin(this.formData);
            await sleep(500);
            this.goTo();
        } catch (error) {
            this.errorData.name = error.name || "Error";
            this.errorData.message = error.message || "Error";
        } finally {
            this.loading = false;
        }
    }

    goTo() {
        if (this.idioma) {
            this.$root.$i18n.locale = this.idioma;
        }
        if (Vue.prototype.$user.get().role == "client") {
            if (this.services?.includes("3")) {
                this.$router.push("/v2/installation");
            } else if (this.services?.includes("2")) {
                this.$router.push("/v2/resumen");
            } else {
                this.$router.push("/resumen");
            }
        } else if (Vue.prototype.$user.get().role == "admin") {
            this.$router.push("/v2/advanced");
        }
    }
}
